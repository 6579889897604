<template>
  <div id="filters-container">
    <div
      class="ui styled accordion"
      @click="displayFilters = !displayFilters"
    >
      <div
        id="filters"
        class="title collapsible-filters"
      >
        FILTRES
        <i
          :class="['ui icon customcaret', { 'collapsed': !displayFilters }]"
          aria-hidden="true"
        />
      </div>
    </div>
    <div :class="['ui menu filters', { 'hidden': displayFilters }]">
      <div class="item">
        <label>
          Niveau d'autorisation requis
        </label>
        <DropdownMenuItem
          :options="accessLevelOptions"
          v-on="$listeners"
        />
      </div>
      <div class="item">
        <label>
          Mon niveau d'autorisation
        </label>
        <DropdownMenuItem
          :options="userAccessLevelOptions"
          v-on="$listeners"
        />
      </div>
      <div class="item">
        <label>
          Modération
        </label>
        <DropdownMenuItem
          :options="moderationOptions"
          v-on="$listeners"
        />
      </div>
      <div class="item">
        <label>
          Recherche par nom
        </label>
        <search-projects
          :search-function="SEARCH_PROJECTS"
          v-on="$listeners"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import DropdownMenuItem from '@/components/Projects/DropdownMenuItem.vue';
import SearchProjects from '@/components/Projects/SearchProjects.vue';

export default {
  name: 'ProjectsMenu',

  components: {
    DropdownMenuItem,
    SearchProjects,
  },

  data() {
    return {
      displayFilters: false,
      moderationOptions: [
        {
          label: 'Tous',
          filter: 'moderation',
          value: null
        },
        {
          label: 'Projet modéré',
          filter: 'moderation',
          value: 'true'
        },
        {
          label: 'Projet non modéré',
          filter: 'moderation',
          value: 'false'
        },
      ],
      accessLevelOptions: [
        {
          label: 'Tous',
          filter: 'access_level',
          value: null
        },
        {
          label: 'Utilisateur anonyme',
          filter: 'access_level',
          value: 'anonymous'
        },
        {
          label: 'Utilisateur connecté',
          filter: 'access_level',
          value: 'logged_user'
        },
        {
          label: 'Contributeur',
          filter: 'access_level',
          value: 'contributor'
        },
      ],
      userAccessLevelOptions: [
        {
          label: 'Tous',
          filter: 'user_access_level',
          value: null
        },
        {
          label: 'Utilisateur connecté',
          filter: 'user_access_level',
          value: '1'
        },
        {
          label: 'Contributeur',
          filter: 'user_access_level',
          value: '2'
        },
        {
          label: 'Super contributeur',
          filter: 'user_access_level',
          value: '3'
        },
        {
          label: 'Modérateur',
          filter: 'user_access_level',
          value: '4'
        },
        {
          label: 'Administrateur projet',
          filter: 'user_access_level',
          value: '5'
        },
      ]
    };
  },

  computed: {
    ...mapState(['user'])
  },

  created() {
    if (!this.user) {
      this.userAccessLevelOptions.splice(1, 0, {
        label: 'Utilisateur anonyme',
        filter: 'user_access_level',
        value: '0'
      });
    }
  },

  methods: {
    ...mapActions('projects', [
      'SEARCH_PROJECTS'
    ]),
  }
};
</script>

<style lang="less" scoped>
.transition-properties(...) {
  -webkit-transition: @arguments;
  -moz-transition: @arguments;
  -o-transition: @arguments;
  transition: @arguments;
}

#filters-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	.accordion {
		width: fit-content;
		.collapsible-filters {
			font-size: 1.25em;
			padding-right: 0;
      .customcaret{
        transition: transform .2s ease;
        &.collapsed {
          transform: rotate(180deg);
        }
        &::before{
          position: relative;
          right: 0;
          top: 65%;
          color: #999;
          margin-top: 4px;
          border-color: #999 transparent transparent;
          border-style: solid;
          border-width: 5px 5px 0;
          content: "";
        }
      }
		}
	}
	.filters {
		width: 100%;
		height:auto;
		min-height: 0;
		max-height:75px;
    opacity: 1;
		margin: 0 0 1em 0;
    border: none;
    box-shadow: none;
		.transition-properties(all 0.2s ease-out;);
		.item {
			display: flex;
			flex-direction: column;
			align-items: flex-start !important;
			padding: 0.5em;

			label {
				margin-bottom: 0.2em;
				font-size: 0.9em;
				font-weight: 600;
			}
		}
		.item {
			width: 25%;
		}
    .item::before {
			width: 0;
		}
    #search-projects {
      width: 100%;
    }
	}
	.filters.hidden {
		overflow: hidden;
    opacity: 0;
    max-height: 0;
	}
}

@media screen and (min-width: 701px) {
  .item {
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
  }
}

@media screen and (max-width: 700px) {
  #filters-container {

    .filters {
      display: flex;
      flex-direction: column;
      max-height: 275px;
      .transition-properties(all 0.2s ease-out;);

      .item {
        width: 100%;
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}
</style>
