<template>
  <div style="display: flex;">
    <nav style="margin: 0 auto;">
      <ul class="custom-pagination">
        <li
          class="page-item"
          :class="{ disabled: currentPage === 1 }"
        >
          <a
            class="page-link pointer"
            @click="changePage(currentPage - 1)"
          >
            <i
              class="ui icon big angle left"
              aria-hidden="true"
            />
          </a>
        </li>
        <div
          v-if="nbPages > 5"
          style="display: contents;"
        >
          <li
            v-for="index in pagination(currentPage, nbPages)"
            :key="index"
            class="page-item"
            :class="{ active: currentPage === index }"
          >
            <a
              class="page-link pointer"
              @click="changePage(index)"
            >
              {{ index }}
            </a>
          </li>
        </div>
        <div
          v-else
          style="display: contents;"
        >
          <li
            v-for="index in nbPages"
            :key="index"
            class="page-item"
            :class="{ active: currentPage === index }"
          >
            <a
              class="page-link pointer"
              @click="changePage(index)"
            >
              {{ index }}
            </a>
          </li>
        </div>
        <li
          class="page-item"
          :class="{ disabled: currentPage === nbPages }"
        >
          <a
            class="page-link pointer"
            @click="changePage(currentPage + 1)"
          >
            <i
              class="ui icon big angle right"
              aria-hidden="true"
            />
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'Pagination',

  props: {
    nbPages: {
      type: Number,
      default: 1
    },
  },

  computed: {
    ...mapState('projects', ['currentPage']),
  },

  methods: {
    ...mapMutations('projects', [
      'SET_CURRENT_PAGE',
    ]),

    pagination(c, m) {
      const current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [];
      let   l;

      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
          range.push(i);
        }
      }
      for (const i of range) {
        if (l) {
          if (i - l === 2) {
            rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
            rangeWithDots.push('...');
          }
        }
        rangeWithDots.push(i);
        l = i;
      }

      return rangeWithDots;
    },

    changePage(pageNumber) {
      if (typeof pageNumber === 'number') {
        this.SET_CURRENT_PAGE(pageNumber);
        // Scroll back to the first results on top of page
        window.scrollTo({ top: 0, behavior: 'smooth' });
        // emit event for parent component to fetch new page data
        this.$emit('page-update', pageNumber);
      }
    }
  }
};
</script>
