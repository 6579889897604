<template>
  <Multiselect
    v-model="selection"
    :options="options"
    :allow-empty="true"
    track-by="label"
    label="label"
    :reset-after="false"
    select-label=""
    selected-label=""
    deselect-label=""
    :searchable="false"
    :placeholder="placeholder"
    :clear-on-select="false"
    :preserve-search="true"
    @select="select"
    @close="close"
  />
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'DropdownMenuItem',

  components: {
    Multiselect
  },

  props: {
    placeholder: {
      type: String,
      default: 'Sélectionnez une valeur'
    },
    options: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      selection: null,
    };
  },

  watch: {
    selection: {
      deep: true,
      handler(newValue) {
        if (!newValue) {
          this.selection = this.options[0];
          this.$emit('filter', this.selection);
        }
      }
    }
  },

  created() {
    this.selection = this.options[0];
  },

  methods: {
    select(e) {
      this.$emit('filter', e);
    },
    close() {
      this.$emit('close', this.selection);
    }
  }
};
</script>
